<template>
	<div class="cats">
		<div class="title">
			<a-col :xs="24" :sm="0">
				<span class="txt">持有资产</span>
			</a-col>
		</div>

		<a-table class="table" :scroll="{ x: 1000 }" :columns="columns" :data-source="dataSource">
		</a-table>
	</div>
</template>

<script>
	import api from '../assets/js_sdk/api.js'

	export default {
		data() {
			return {
				fixedTop: false,
				dataSource: null,
				columns: [{
						title: '代币名称',
						dataIndex: 'name',
						key: 'name',
						ellipsis: true,
						width: 100,
					},
					{
						title: '代币符号',
						dataIndex: 'symbol',
						key: 'symbol',
						ellipsis: true,
						width: 100,
					}, {
						title: 'Token_ID',
						dataIndex: 'token_id',
						key: 'token_id',
						ellipsis: true,
						width: 100,

					},
					{
						title: '小数位',
						dataIndex: 'decimals',
						key: 'decimals',
						ellipsis: true,
						width: 100,
					},
					{
						title: '余额',
						dataIndex: 'amount',
						key: 'amount',
						ellipsis: true,
						width: 100,
						fixed: 'right'
					},
				],


				limit: 10,
				offset: 0
			}
		},
		computed: {
			wallet() {
				return this.$store.getters.getWallet;
			}
		},
		mounted() {
			this.getHolds()
		},
		methods: {
			async getHolds() {
				const result = await api.getHolds(this.wallet, 100, 0)
				if (result.data) {
					let data = result.data
					this.dataSource = data
				}
			}
		}
	}
</script>

<style lang="scss">
	$text-main-color: #242b21;

	.cats {
		width: 100%;
		height: 92.5vh;
		display: flex;
		flex-direction: column;
		align-items: center;

		.title {
			margin-top: 30px;
			margin-bottom: 30px;

			.txt {
				font-size: 30px;
				color: $text-main-color;
				font-weight: bold;
			}
		}
	
		.table {
			width: 100%;
			max-width: 1200px;
		}
	}
</style>