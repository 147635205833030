import forge from 'node-forge';

function truncateToFixed(num, n) {
	// 如果n小于0，则没有意义，可以返回一个错误或默认字符串
	if (n < 0) {
		return 'Invalid n value';
	}

	// 先将数字转换为字符串
	let numStr = num.toString();

	// 检查是否包含小数点
	if (!numStr.includes('.')) {
		// 如果没有小数点，且n大于0，则返回整数后加小数点和n个0
		if (n > 0) {
			return numStr + '.'.repeat(1) + '0'.repeat(n);
		}
		// 如果n等于0，则直接返回整数
		return numStr;
	}

	// 分割字符串为整数部分和小数部分
	let [integerPart, decimalPart] = numStr.split('.');

	// 截断小数部分到n位
	decimalPart = decimalPart.length > n ? decimalPart.substr(0, n) : decimalPart;

	// 重新组合整数部分和小数部分
	return integerPart + '.' + decimalPart;
}


function formatNumberWithCommas(numStr) {
	// 去除任何可能存在的逗号
	numStr = numStr.replace(/,/g, '');
	// 分割整数部分和小数部分（如果有）
	let parts = numStr.split('.');
	let integerPart = parts[0];
	let decimalPart = parts.length > 1 ? '.' + parts[1] : '';

	// 在每三位数字后添加逗号
	let formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

	// 合并整数部分和小数部分（如果有）
	return formattedIntegerPart + decimalPart;
}


function subString(data, begin, end) {
	if (data) {
		return data.substring(0, begin) + '...' + data.substring(
			data.length -
			end, data.length)
	}
	return ''
}


export default {
	truncateToFixed,
	formatNumberWithCommas,
	subString,
}