// store.js
import Vuex from 'vuex'

const store = new Vuex.Store({
	state: {
		view:"MintView",
		address: 'Wallet not connected',
		mintWallet: null
	},
	mutations: {
		updateView(state, newVal) {
			state.view = newVal
		},
		updateWallet(state, newVal) {
			state.address = newVal
		},
		updateMintWallet(state, newVal) {
			state.mintWallet = newVal
		}
	},
	getters: {
		getView: (state) => {
			return state.view
		},
		getWallet: (state) => {
			return state.address
		},
		
		getMintWallet: (state) => {
			return state.mintWallet
		},
	}
})

export default store