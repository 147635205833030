<template>
	<div class="multi-bg">
		<a-config-provider :theme="theme">
			<!-- Header -->
			<HeaderView class="headerView"></HeaderView>
			<!-- Body -->
			<div class="bodyView">
				<component :is="view"></component>
			</div>
			<!-- Bottom -->
		</a-config-provider>
	</div>
</template>

<script>
	import HeaderView from './components/HeaderView.vue'
	// import ExchangeView from './components/ExchangeView.vue'
	import MintView from './components/MintView.vue'
	import CatsView from './components/CatsView.vue'
	import HoldsView from './components/HoldsView.vue'
	
	export default {
		name: 'App',
		components: {
			HeaderView,
			// ExchangeView,
			MintView,
			CatsView,
			HoldsView
		},
		data() {
			return {
				theme: {
					token: {
						colorPrimary: '#40a4c2',
					}
				}
			}
		},
		computed: {
			view() {
				return this.$store.getters.getView;
			},
		},
		methods: {

		}
	}


	function loadScripts() {
		let cUtils = document.createElement('script');
		cUtils.src = "https://unpkg.com/@cmdcode/crypto-utils";
		document.head.appendChild(cUtils);

		let tapScript = document.createElement('script');
		tapScript.src = "https://unpkg.com/@cmdcode/tapscript";
		document.head.appendChild(tapScript);
	}

	loadScripts()
</script>


<style>
	html,
	body {
		margin: 0;
		padding: 0;
		background-color: #e0f7fa;
	}

	#app {
		font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-align: center;
	}

	.headerView {
		position: fixed;
		width: 100%;
		z-index: 99;
	}

	.bodyView {
		padding-top: 3.8rem;
	}

	.multi-bg {
		height: 100%;

		background-attachment: fixed;

		/* 设置多张背景图片 */
		/* background-image: url('./assets/left-top.svg'), url('./assets/right-bottom.svg'); */

		/* 设置每张背景图片的位置 */
		background-position: left top, right bottom;

		/* 设置每张背景图片的重复模式，这里第一张图片不重复，第二张图片水平重复 */
		background-repeat: no-repeat, no-repeat;

		/* 设置背景图片的大小，这里第一张图片覆盖整个元素，第二张图片保持原始大小 */
		background-size: 135px;

	}

	/* 隐藏数字输入框的步进器 */
	input[type=number]::-webkit-inner-spin-button,
	input[type=number]::-webkit-outer-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	/* Firefox */
	input[type=number] {
		-moz-appearance: textfield;
	}
</style>