import {
	createApp
} from 'vue';
import App from './App';
import Vuex from 'vuex'

import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/reset.css';
import * as Icons from '@ant-design/icons-vue' //图标

import * as vant from 'vant';

// 2. 引入组件样式
import 'vant/lib/index.css';


//axios
import axios from './assets/js_sdk/axios';

//Vuex
import store from './vuex/store.js';

import unisat from './assets/js_sdk/unisat.js'
import okx from './assets/js_sdk/okx.js'
import xverse from './assets/js_sdk/xverse.js';

const app = createApp(App).use(Antd);


if (typeof window.unisat !== 'undefined') {
	app.config.globalProperties.$wallet = unisat
} else if (typeof window.okxwallet !== 'undefined') {
	app.config.globalProperties.$wallet = okx
}else{
	app.config.globalProperties.$wallet = xverse
} 


app.config.globalProperties.$network = 'testnet'

app.config.globalProperties.$brc20pm = "https://f37526546h.goho.co"

app.config.globalProperties.$store = store

app.config.globalProperties.$axios = axios



// 注册图标组件
for (const i in Icons) {
	app.component(i, Icons[i])
}

app.use(vant);

// Lazyload 指令需要单独进行注册
app.use(vant.Lazyload);

app.use(Vuex);

app.mount('#app');