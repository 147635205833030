const okxwallet = window.okxwallet

var walletAddr = null

export default {
	async Connect() {
		const result = await okxwallet.bitcoin.connect()
		walletAddr = result.address
		return walletAddr
	},
	async GetBalance() {
		let res = await okxwallet.bitcoin.getBalance();
		return res.total / 100000000
	},
	async GetAccount() {
		return walletAddr;
	},
	async Send(to, sat) {
		let txid = await okxwallet.bitcoin.sendBitcoin(
			to,
			sat
		);

		return txid
	},
	async Broadcast(hex) {
		let txid = await okxwallet.bitcoin.pushTx(hex);
		return txid;
	}
}