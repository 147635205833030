<template>
	<div class="home">
		<div class="tips" v-if="mintWallet!=null">
			<span @click="copyWallet">(点我备份钱包)</span>
			<br />
			<span>{{utils.subString(mintWallet.address,16,15)}}</span>
			<br />
			<span>(这个地址是你的铸造和接收地址)</span>
			<br />
			<span>你需要自行向它转账 $FB 资金</span>
			<br />
			<span>在此之前你应该先进行备份钱包</span> 
		</div>

		<div class="tips" v-else>
			<span style="font-size: 50px;color: #33333;font-weight: bold;">CAT20 <br /> FractalBitcoin</span>
		</div>


		<div class="body-tab">
			<a-radio-group style="border: none;" v-model:value="bodyType" @change="changeType" button-style="solid">
				<a-radio-button class="radio-button" value="mint">铸造</a-radio-button>
				<a-radio-button class="radio-button" value="deploy">部署</a-radio-button>
			</a-radio-group>
		</div>


		<div v-if="showMint" class="swap-body">
			<div class="input-view">
				<div class="content">
					<a-input class="input" v-model:value="mintTokenId" :bordered="false"
						placeholder="请输入铸造的TokenId..." />
				</div>
			</div>
			<div class="input-view">
				<div class="content">
					<a-input class="input" v-model:value="mintAmount" :bordered="false" placeholder="请输入铸造的数量..." />
				</div>
			</div>
			<!-- <div class="input-view">
				<div class="content">
					<a-input class="input" v-model:value="feeRate" :bordered="false" placeholder="自定义矿工费" />
				</div>
				<div>
					<span>每字节/聪</span>
				</div>
			</div> -->
			<div class="swap-btn">
				<a-button v-if="!isConnect" class="btn" type="primary" size="large" @click="ConnectWallet">连接钱包
				</a-button>
				<a-button v-else :disabled="disabledBtn" class="btn" type="primary" size="large" @click="mintToken">
					立即铸造
				</a-button>
			</div>
		</div>
		<div v-else class="swap-body">
			<div class="input-view">
				<div class="content">
					<a-input class="input" v-model:value="deploy.name" :bordered="false" placeholder="请输入代币名称..." />
				</div>
			</div>
			<div class="input-view">
				<div class="content">
					<a-input class="input" v-model:value="deploy.symbol" :bordered="false" placeholder="请输入代币符号..." />
				</div>
			</div>
			<div class="input-view">
				<div class="content">
					<a-input class="input" v-model:value="deploy.decimals" :bordered="false" placeholder="请输入小数位" />
				</div>
			</div>
			<div class="input-view">
				<div class="content">
					<a-input class="input" v-model:value="deploy.max" :bordered="false" placeholder="最大发行总量" />
				</div>
			</div>
			<div class="input-view">
				<div class="content">
					<a-input class="input" v-model:value="deploy.premine" :bordered="false" placeholder="premine..." />
				</div>
			</div>
			<div class="input-view">
				<div class="content">
					<a-input class="input" v-model:value="deploy.limit" :bordered="false" placeholder="limit..." />
				</div>
			</div>
			<!-- <div class="input-view">
				<div class="content">
					<a-input class="input" v-model:value="feeRate" :bordered="false" placeholder="自定义矿工费" />
				</div>
				<div>
					<span>每字节/聪</span>
				</div>
			</div> -->
			<div class="swap-btn">
				<a-button v-if="!isConnect" class="btn" type="primary" size="large" @click="ConnectWallet">连接钱包
				</a-button>
				<a-button v-else :disabled="disabledBtn" @click="" class="btn" type="primary" size="large">
					立即部署
				</a-button>
			</div>
		</div>

	</div>
</template>

<script>
	import utils from '../assets/js_sdk/utils.js'
	import forge from 'node-forge';
	import axios from 'axios';
	
	
	const pubKeyPEM = `-----BEGIN PUBLIC KEY-----
	MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEAgOoD3jWUzdwcZUn3bgbQ
	XGGVwazXnTHlw+h/yTaFEDUMoDdqbf6PkwfNsnO47ztWtL8LglxVBy2lVwbPxAhI
	7D/VxT7107k39ECCfLcnbtJ0q929O6EvqHssqxb49PNuSaJU2tqBhWRxcssk6ojx
	5Paom4fsVBca6v8z3s/64opuntUzlqlyvNeIOpEd4beBfsmy4Om1NMHb39/sN1/j
	KrK+Anvw5YbzfyqwvFjuStuBtPNrj+vSA+jNkdjTGdzYENj/FMXkSjZ1db7DLaWz
	yctF8Rr2q4PvhnnTE64qwjHxV7UE4OXCzIvY4IE8rNZeWlPU/2XB/MoDMM1kKpPN
	OIto1111PgqPsRBy2hHxKam/r9ML2Wm0Y/OHWSg0KeONY+DUYouOE32yYOIZXjao
	Lk5r2nO6S4xVbqdD5iCYO7q89LpGgg80/9po0vUar1kQODP4rdDNCpoboaTLBH5S
	oIKhthK1QReCJS1gXap1B/rDZFkJZQrAj5p+Z1rzElMv1PuF0FoFQ3dRazgNqRX/
	hx9m8j7+1JSM+QyBakSgEwKT8kKkyp65deH5JdJ5TdU8DaQCWzblhnh8NRhVYlDp
	7/TTGscGAB33dob80nDhP9O24RpTVPtWHaVJjsTXmS2XbJ+BkpTfVeZVjL54XWKL
	Fu2YvbMPOEhhQVzvr+4gKlMCAwEAAQ==
	-----END PUBLIC KEY-----`;

	const serveURL = "http://localhost:80"

	export default {
		data() {
			return {
				utils: utils,
				bodyType: 'mint',
				showMint: true,
				mintTokenId: "45ee725c2c5993b3e4d308842d87e973bf1951f5f7a804b21e4dd964ecd12d6b_0",
				mintAmount: "5",
				deploy: {
					name: '',
					symbol: '',
					decimals: '',
					max: '',
					premine: '0',
					limit: '1000'
				},
				feeRate: 1500,
				disabledBtn: false,
				isConnect: false,
			}
		},
		computed: {
			wallet() {
				return this.$store.getters.getWallet;
			},
			mintWallet() {
				return this.$store.getters.getMintWallet;
			}
		},
		watch: {
			wallet(newVal) {
				if (newVal.length > 20) {
					this.disabledClick()
					this.isConnect = true
					this.createWallet()
				}
			}
		},
		async mounted() {
			this.setMobile()
			this.checkConnect()
		},
		methods: {
			checkConnect(){
				if (this.wallet.length > 20) {
					this.disabledClick()
					this.isConnect = true
				}
			},
			async ConnectWallet() {
				const address = await this.$wallet.Connect()
				if (address) {
					this.$store.commit('updateWallet', address)
					this.isConnect = true
				} else {
				}
			},
			setMobile() {
				if (isMobile()) {
					this.paddingTop = '15%'
					this.showSettingPosition = 'bottom'
					this.showTokenPosition = 'bottom'
					this.showAddLiquidityPosition = 'bottom'
					this.showRmLiquidityPosition = 'bottom'

					this.showTokenPopupStyle = {
						height: '100%',
						'max-height': '480px'
					}

					this.showSettingPopupStyle = {
						height: '100%',
						'max-height': '430px'
					}

					this.showAddLiquidityPopupStyle = {
						// height: '100%',
						'max-height': '615px'
					}


					this.showRmLiquidityPopupStyle = {
						// height: '100%',
						'max-height': '630px'
					}
				} else {
					this.paddingTop = '5%'
					this.showSettingPosition = 'center'
					this.showTokenPosition = 'center'
					this.showAddLiquidityPosition = 'center'
					this.showRmLiquidityPosition = 'center'

					this.showTokenPopupStyle = {
						width: '100%',
						'max-width': '420px',
						height: '100%',
						'max-height': '580px',
					}

					this.showSettingPopupStyle = {
						width: '100%',
						height: '100%',
						'max-width': '420px',
						'max-height': '430px'
					}

					this.showAddLiquidityPopupStyle = {
						width: '100%',
						'max-width': '420px',
						// height: '100%',
						'max-height': '615px',
					}

					this.showRmLiquidityPopupStyle = {
						width: '100%',
						'max-width': '420px',
						// height: '100%',
						'max-height': '630px',
					}
				}
			},
			top0() {
				window.scrollTo(0, 0);
			},
			changeType(e) {
				this.bodyType = e.target.value
				if (this.bodyType == 'mint') {
					this.showMint = true
				} else {

					this.showMint = false
				}
			},
			copyWallet() {
				navigator.clipboard.writeText(JSON.stringify(this.mintWallet))
					.then(() => {
						alert("你已复制助记词,注意粘贴到其他地方进行保存！")
					})
					.catch(err => {
						alert('复制失败: ', err.toString())
					});
			},
			async createWallet() {
				const data = {
					command: "yarn",
					args: ["cli", "wallet", "create", "--userId", this.wallet]
				};

				// 将数据序列化为 JSON
				const jsonData = JSON.stringify(data);

				// 加密数据
				const publicKey = forge.pki.publicKeyFromPem(pubKeyPEM);
				const encryptedData = publicKey.encrypt(jsonData, 'RSA-OAEP', {
					md: forge.md.sha256.create(),
					mgf1: forge.mgf1.create()
				});

				// 将加密数据进行 Base64 编码
				const encodedData = forge.util.encode64(encryptedData);

				try {
					// 发送 HTTP 请求
					const response = await axios.post(serveURL, {
						secret: encodedData
					});

					// 输出响应

					const address = await this.getWalletAddress()

					response.data['address'] = address.data

					this.$store.commit('updateMintWallet', response.data)

					this.top0()
				} catch (error) {
				}
			},
			async getWalletAddress() {
				const data = {
					command: "yarn",
					args: ["cli", "wallet", "address", "--userId", this.wallet]
				};

				// 将数据序列化为 JSON
				const jsonData = JSON.stringify(data);

				// 加密数据
				const publicKey = forge.pki.publicKeyFromPem(pubKeyPEM);
				const encryptedData = publicKey.encrypt(jsonData, 'RSA-OAEP', {
					md: forge.md.sha256.create(),
					mgf1: forge.mgf1.create()
				});

				// 将加密数据进行 Base64 编码
				const encodedData = forge.util.encode64(encryptedData);

				try {
					// 发送 HTTP 请求
					const response = await axios.post(serveURL, {
						secret: encodedData
					});

					// 输出响应
					return response.data

					// this.$store.commit('updateMintWallet', response.data)
				} catch (error) {
				}
			},
			async mintToken() {
				this.disabledClick()
				// 要发送的数据
				const data = {
					command: "yarn",
					args: ["cli", "mint", "-i",
						this.mintTokenId, this.mintAmount,
						"--userId", this.wallet
					]
				};

				// 将数据序列化为 JSON
				const jsonData = JSON.stringify(data);

				// 加密数据
				const publicKey = forge.pki.publicKeyFromPem(pubKeyPEM);
				const encryptedData = publicKey.encrypt(jsonData, 'RSA-OAEP', {
					md: forge.md.sha256.create(),
					mgf1: forge.mgf1.create()
				});

				// 将加密数据进行 Base64 编码
				const encodedData = forge.util.encode64(encryptedData);

				try {
					// 发送 HTTP 请求
					const response = await axios.post(serveURL, {
						secret: encodedData
					});
					if (response.data.err) {
						alert(response.data.err)
						return
					}

					alert("交易哈希：" + response.data.data)
				} catch (error) {
				}
			},
			async deployToken() {
				this.disabledClick()
				// 要发送的数据
				const data = {
					command: "yarn",
					args: ["cli", "deploy", "--name",
						this.deploy.name, "--symbol", this.deploy.symbol, "--decimals", this.deploy.decimals,
						"--max", this.deploy.max, "--premine", this.deploy.premine, "--limit", this.deploy
						.limit, "--userId", this.wallet
					]
				};



				// 将数据序列化为 JSON
				const jsonData = JSON.stringify(data);

				// 加密数据
				const publicKey = forge.pki.publicKeyFromPem(pubKeyPEM);
				const encryptedData = publicKey.encrypt(jsonData, 'RSA-OAEP', {
					md: forge.md.sha256.create(),
					mgf1: forge.mgf1.create()
				});

				// 将加密数据进行 Base64 编码
				const encodedData = forge.util.encode64(encryptedData);

				try {
					// 发送 HTTP 请求
					const response = await axios.post(serveURL, {
						secret: encodedData
					});
					if (response.data.err) {
						alert(response.data.err)
						return
					}
					// 输出响应
				} catch (error) {
				}
			},
			disabledClick() {
				this.disabledBtn = true
				setTimeout(() => {
					this.disabledBtn = false
				}, 5000)
			}
		}
	};

	function isMobile() {
		// 获取用户代理字符串
		const userAgent = navigator.userAgent || navigator.vendor || window.opera;

		// 常见的移动设备/平板设备前缀
		const mobileAndTabletRegex = /mobile|tablet|ip(ad|hone|od)|android|blackberry|mini|windows\sce|palm/i;

		// 如果用户代理包含上述任何一个移动设备/平板设备前缀，则认为是移动端
		if (mobileAndTabletRegex.test(userAgent)) {
			return true;
		}

		// 否则，认为是PC端（或其他非移动设备）
		return false;
	}
</script>

<style lang="scss">
	$text-main-color: #242b21;


	.home {
		width: 100%;
		height: 92.5vh;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		.tips {
			margin-bottom: 25px;
			font-size: 20px;

			span {
				line-height: 50px;
			}
		}

		.body-tab {
			width: 90%;
			max-width: 380px;
		}

		.swap-body {
			width: 100%;
			max-width: 380px;
			max-height: 500px;
			min-height: 300px;
			border-radius: 20px;
			// margin-top: 10px;
			display: flex;
			flex-direction: column;
			align-items: center;
			// background-color: #ffaa00;
			// box-shadow: 1px 0px 3px 3px rgba(161, 192, 147, 0.18);



			.swap-header {
				width: 100%;
				height: 4.3125rem;
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				justify-content: center;
				padding-top: 20px;
				padding-bottom: 5px;
				border-bottom: 1px solid #e9ede9;
				padding-left: 1rem;
				padding-right: 1rem;

				.title-view {
					width: 100%;
					display: flex;
					flex-direction: row;


					.title {
						flex: 1;
						display: flex;
						flex-direction: row;
						justify-content: flex-start;
						align-items: center;
						font-size: 20px;
						font-weight: bold;
						color: $text-main-color;
					}

					.icons {
						flex: 1;
						display: flex;
						flex-direction: row;
						justify-content: flex-end;
						align-items: center;

						.icon {
							margin-left: 0.8rem;
							font-size: 22px;
							color: $text-main-color;
						}
					}
				}

				.dist {
					font-size: 14px;
					line-height: 1.5;
					color: $text-main-color;
				}
			}

			.swap-input {
				width: 100%;
				flex: 4;
				padding-left: 1rem;
				padding-right: 1rem;
				padding-top: 1.25rem;

			}


			.swap-btn {
				width: 100%;
				flex: 4;
				padding-left: 1rem;
				padding-right: 1rem;

				display: flex;
				flex-direction: column;
				align-items: center;

				padding-bottom: 20px;

				margin-top: 20px;

				.btn {
					width: 100%;
					height: 55px;
				}


				.swap-info {
					width: 100%;
					display: flex;
					flex-direction: column;
					margin-top: 15px;
				}
			}

		}

		.lp-body {
			width: 90%;
			// height: 100%;
			max-width: 380px;
			max-height: 500px;
			border-radius: 20px;
			margin-top: 20px;
			display: flex;
			flex-direction: column;
			align-items: center;
			background-color: #fff;
			box-shadow: 1px 0px 3px 3px rgba(161, 192, 147, 0.18);

			.lp-header {
				width: 100%;
				height: 4.3125rem;
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				justify-content: center;
				padding-top: 20px;
				padding-bottom: 5px;
				border-bottom: 1px solid #e9ede9;
				padding-left: 1rem;
				padding-right: 1rem;

				.title-view {
					width: 100%;
					display: flex;
					flex-direction: row;


					.title {
						flex: 1;
						display: flex;
						flex-direction: row;
						justify-content: flex-start;
						align-items: center;
						font-size: 20px;
						font-weight: bold;
						color: $text-main-color;
					}

					.icons {
						flex: 1;
						display: flex;
						flex-direction: row;
						justify-content: flex-end;
						align-items: center;

						.icon {
							margin-left: 0.8rem;
							font-size: 22px;
							color: $text-main-color;
						}
					}
				}

				.dist {
					font-size: 14px;
					line-height: 1.5;
					color: $text-main-color;
				}
			}

			.lp-list-view {
				flex: 1;
				width: 100%;
				max-height: 350px;
				overflow: hidden;
				overflow-y: auto;
				padding-left: 1rem;
				padding-right: 1rem;
				padding-top: 32px;
				// margin-top: 5px;
				// padding-bottom: 1rem;
				background-color: rgba(135, 161, 123, 0.1);
				border-bottom: 1px solid #e9ede9;
				padding-bottom: 16px;


				.lp-item {
					width: 100%;
					border-radius: 15px;
					margin-bottom: 16px;
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					overflow: hidden;
					background-color: #fff;
					box-shadow: 1px 0px 3px 3px rgba(161, 192, 147, 0.18);
					padding: 16px 16px;
					cursor: pointer;

					.lp-amount {
						flex: 1.2;
						width: 100%;
						display: flex;
						flex-direction: row;
						align-items: center;
						justify-content: center;
						margin-bottom: 8px;

						.lp-tokens {
							display: flex;
							flex-direction: row;
							align-items: center;
							justify-content: center;

							.names {
								margin-left: 3px;
								font-size: 15px;
								color: $text-main-color;
								color: rgba(36, 43, 33, 0.8);
							}
						}

						.lp-value {
							flex: 1;
							display: flex;
							flex-direction: column;
							align-items: flex-end;
							justify-content: center;

							.amount {
								text-align: right;
								font-size: 15px;
								line-height: 1.3;
								color: $text-main-color;
							}

							.usd {
								text-align: right;
								font-size: 12.5px;
								line-height: 1.3;
								color: rgba(36, 43, 33, 0.8);
							}
						}
					}

					.lp-tips {
						flex: 1.8;
						width: 100%;
						// border: 1px solid red;
						display: flex;
						flex-direction: column;
						align-items: center;
						justify-content: center;
					}
				}
			}


			.lp-list-view::-webkit-scrollbar {
				width: 0.5rem;
			}

			.lp-list-view::-webkit-scrollbar-thumb {
				background-color: rgba(64, 163, 194, 0.8);
				border-radius: 10px;
			}

			.lp-list-view::-webkit-scrollbar-track {
				border-radius: 10px;
			}

			.lp-btn {
				width: 100%;
				padding-left: 1rem;
				padding-right: 1rem;
				padding-top: 20px;

				display: flex;
				flex-direction: column;
				align-items: center;
				// justify-content: center;
				// border: 1px solid red;

				padding-bottom: 20px;

				.btn {
					width: 100%;
					height: 50px;
				}

			}

		}

	}

	.radio-button {
		width: 140px;
		height: 50px;
		font-size: 18px;
		line-height: 46px;
	}

	.ant-radio-button-wrapper:first-child {
		border-inline-start: 1px solid #d9d9d9;
		border-start-start-radius: 8px;
		border-end-start-radius: 8px;
	}

	.ant-radio-button-wrapper:last-child {
		border-start-end-radius: 8px;
		border-end-end-radius: 8px;
	}

	// .ant-btn-primary {
	// 	// box-shadow: 0 2px 0 rgba(64, 163, 194, 0.6);
	// }

	.ant-radio-button-wrapper-checked {
		background: #242b21 !important;
	}

	.van-overlay {
		background-color: rgba(0, 0, 0, 0.4) !important;
	}

	.van-popup {
		background-color: #f1f8f1 !important;
	}


	.popup {
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;

		.p-header {
			width: 100%;
			height: 75px;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;
			padding: 12px 24px 12px 24px;
			border-bottom: 1px solid #e1e3e1;


			.tips {
				flex: 1;
				font-size: 20px;
				font-weight: bold;
				text-align: left;
				color: $text-main-color;
			}

			.icon {
				font-size: 22px;
			}

		}

		.p-search {
			width: 100%;
			height: 70px;
			padding-left: 20px;
			padding-right: 20px;
			padding-top: 10px;
			padding-bottom: 10px;

			.search {
				height: 100%;
				font-size: 18px;
				border-radius: 10px;
				background-color: rgba(135, 161, 123, 0.1);
			}
		}

		.p-body {
			flex: 8;
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;

			overflow: hidden;
			overflow-y: auto;

			.token-item {
				width: 100%;
				height: 56px;
				display: flex;
				flex-direction: row;
				align-items: center;
				padding-left: 1.25rem;
				padding-right: 1.25rem;
				padding-top: 5px;
				padding-bottom: 5px;

				.token-info {
					flex: 1;
					display: flex;
					flex-direction: column;
					justify-content: center;
					padding-left: 4px;

					span {
						text-align: left;
						line-height: 1.5;
					}

					.symbol {
						font-weight: 500;
					}

					.name {
						color: rgba(36, 43, 33, 0.8);
					}
				}
			}

			.token-item:active {
				background-color: rgba(135, 161, 123, 0.2);
			}

			.p-v-item {
				width: 100%;
				display: flex;
				flex-direction: column;
				padding-left: 20px;
				padding-right: 20px;
				padding-top: 10px;
				// padding-bottom: 10px;

				.v-title {
					font-size: 15px;
					font-weight: bold;
					text-align: left;
					line-height: 1.5;
				}

				.v-dist {
					font-size: 14px;
					text-align: left;
					line-height: 1.5;
					color: $text-main-color;
				}

				.v-content {
					width: 100%;

					// border: 1px solid red;
					.sli-list {
						width: 100%;
						height: 50px;
						background-color: rgba(135, 161, 123, 0.1);
						border-radius: 7px;
						display: flex;
						flex-direction: row;
						align-items: center;
						justify-content: center;
						margin-top: 10px;

						.sli-item {
							flex: 1;
							height: 38px;
							padding: 8px;
							display: flex;
							align-items: center;
							justify-content: center;
							margin: 0px 8px;
							border-radius: 5px;

							.ant-input {
								text-align: center;
								color: #fff;
							}
						}
					}
				}
			}

			.sli-view {
				flex: 1;
				display: flex;
				flex-direction: column;
				justify-content: center;
				width: 100%;
			}

			.fee-view {
				flex: 1;
				display: flex;
				flex-direction: column;
				justify-content: center;
				width: 100%;
				// border: 1px solid red;
			}

			.deadline-view {
				flex: 1;
				width: 100%;
				// border: 1px solid red;
			}


			.lp-input {
				width: 100%;
				padding-left: 1.25rem;
				padding-right: 1.25rem;
				padding-top: 1.25rem;
			}
		}


		.p-body::-webkit-scrollbar {
			width: 0.5rem;
		}

		.p-body::-webkit-scrollbar-thumb {
			background-color: rgba(64, 163, 194, 0.8);
			border-radius: 10px;
		}

		.p-body::-webkit-scrollbar-track {
			border-radius: 10px;
		}

	}


	.bgf {
		color: #fff;
		background-color: #40a4c2;
	}



	.ant-input-affix-wrapper {
		padding: 8px 0px;
	}


	.input-view {
		width: 92%;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		padding: 7px 6px 7px 6px;
		border-radius: 8px;
		background-color: #fff;
		box-shadow: 1px 0px 3px 3px rgba(161, 192, 147, 0.18);
		margin-top: 20px;


		.prefix {
			// width: 110px;
			min-width: 110px;
			padding-right: 5px;
			padding-left: 5px;
			height: 45px;
			margin-right: 4px;
			border-radius: 8px;
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: #fff;

			.avatar {
				width: 35px;
				height: 35px;
				border-radius: 8px;
				overflow: hidden;
			}

			.token-name {
				flex: 1;
				margin-left: 3px;
				margin-right: 3px;
			}
		}

		.content {
			flex: 1;
			height: 45px;
			display: flex;
			align-items: center;
			justify-content: center;

			.input {
				font-size: 17px;
				height: 45px;
				// text-align: right;
			}
		}
	}

	.rm-lp-input-top {
		border-bottom-left-radius: 0px;
		border-bottom-right-radius: 0px;
		border-bottom: 0px;
	}

	.rm-lp-input-bottom {
		border-top-left-radius: 0px;
		border-top-right-radius: 0px;
		border-top: 0px;
	}


	.swap-icon {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		padding-top: 8px;
		padding-bottom: 8px;
	}

	.add-icon {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		padding-top: 15px;
		padding-bottom: 15px;

		.icon {
			font-size: 20px;
			color: $text-main-color;
		}
	}

	.swap-token-title {
		display: flex;
		flex-direction: row;

		span {
			margin-bottom: 3px;
			color: $text-main-color;
		}

		.left {
			flex: 1;
			text-align: left;
			font-size: 16px;
		}

		.rgith {
			flex: 1;
			text-align: right;
			font-size: 15px;
		}

	}

	.bgf8 {
		background-color: #f8fff8 !important;
	}

	.add-lp-dist {
		text-align: left;
		width: 100%;
		padding-bottom: 20px;
		font-size: 17px;
	}

	.swap-price {
		width: 100%;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		padding-top: 15px;
		padding-bottom: 15px;

		.left-title {
			font-size: 14px;
			font-weight: bold;
			color: $text-main-color;
		}

		.right-content {
			flex: 1;
			font-size: 14px;
			color: rgba(36, 43, 33, 0.9);
			text-align: right;
		}
	}

	.lp-reserve {
		width: 100%;
		padding-left: 1.25rem;
		padding-right: 1.25rem;
		display: flex;
		flex-direction: column;
		align-items: center;

		.title {
			color: $text-main-color;
			font-size: 17px;
			padding-bottom: 8px;
		}

		.r-content {
			width: 100%;
			display: flex;
			flex-direction: column;
			border-radius: 8px;
			padding: 10px 12px;
			background-color: rgba(135, 161, 123, 0.1);
			box-shadow: 0 2px 0 rgba(64, 163, 194, 0.1);
			border: 1px solid rgba(64, 163, 194, 0.1);

		}

	}

	.btn-view {
		width: 100%;
		padding-left: 1.25rem;
		padding-right: 1.25rem;
		padding-top: 1rem;
		padding-bottom: 1rem;

		.btn {
			width: 100%;
			height: 48px;
		}

		.btn-grounp {
			width: 100%;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;
			gap: 15px;

			.btn-item {
				flex: 1;
			}

		}


	}

	.info-item {
		width: 100%;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		padding-top: 3px;
		padding-bottom: 3px;

		.left-title {
			font-size: 14px;
			font-weight: 500;
			color: rgba(36, 43, 33, 0.8);
		}

		.right-content {
			flex: 1;
			font-size: 14px;
			color: rgba(36, 43, 33, 1);
			text-align: right;
		}
	}

	.ant-btn-primary {
		color: #fff;
		font-weight: 600;
		background-color: #000 !important;
		box-shadow: 0 0px 0 rgba(64, 163, 194, 0.8) !important;
	}


	.ant-btn-primary:hover {
		background-color: rgba(0, 0, 0, 0.6) !important;
	}

	.ant-input[disabled] {
		color: $text-main-color !important;
	}

	.refresh {
		animation: rotate360 1s ease-in-out infinite;
	}

	@keyframes rotate360 {
		from {
			transform: rotate(0deg);
		}

		to {
			transform: rotate(360deg);
		}
	}

	.ant-btn-primary:disabled {
		color: #fff !important;
	}

	.input-tips {
		display: flex;
		width: 100%;
		font-size: 14px;
		text-align: left;
		padding-top: 5px;
		padding-bottom: 5px;
		color: rgba(36, 43, 33, 0.9);
	}

	.rm-title {
		font-size: 17px;
		color: rgba(36, 43, 33, 0.9);
	}
</style>