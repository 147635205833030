const unisat = window.unisat

var walletAddr = null

export default {
	async Connect() { 
		try {
			let accounts = await unisat.requestAccounts();
			console.log(accounts)
			walletAddr = accounts[0]
			return walletAddr
		} catch (e) {
			console.log(e);
		}
	},
	async GetBalance() {
		try {
			let res = await unisat.getBalance();
			return res.total / 100000000
		} catch (e) {
			console.log(e);
		}
	},
	async GetAccount() {
		return walletAddr;
	},
	async Send(to, sat) {
		try {
			let txid = await unisat.sendBitcoin(to, sat);
			return txid
		} catch (e) {
			console.log(e);
		}

	},
	async Broadcast(hex) {
		try {
			let txid = await unisat.pushTx({
				rawtx: hex
			});
			return txid;
		} catch (e) {
			console.log(e);
		}
	}
}