import axios from "axios"

// 创建axios实例
const service = axios.create({
	timeout: 60000,
});

//发送GET请求
/**
 * @param {string} url
 */
async function get(url) {
	return await service.get(url)
}

//发送POST请求
/**
 * @param {string} url
 * @param {any} data
 */
async function post(url, data) {
	return await service.post(url, data)
}


function post2(url, data) {
	return service.post(url, data)
}



export default {
	get,
	post,
	post2
}