import axios from './axios.js'


const SERVE_URL = "https://a2b.finance/api"

export default {

	getTokens(limit, offset) {
		return axios.post(SERVE_URL + "/tokens", {
			limit: limit.toString(),
			offset: offset.toString()
		})
	},
	getHolds(owner, limit, offset) {
		return axios.post(SERVE_URL + "/holds", {
			owner,
			limit: limit.toString(),
			offset: offset.toString()
		})
	},
}