<template>
	<div class="header">
		<div class="menu">
			<div class="logo">
				<a-col :xs="0" :sm="6">
					<img class="icon" src="../assets/icon.png" width="50px" height="45px" />
				</a-col>
				<span class="domain">FractalCat.xyz</span>
			</div>
			<div class="other">
				<a-row class="tab">
					<a-col :xs="0" :sm="20">
						<div class="tab-list">
							<span class="tab-item" v-for="(item,index) in ServiceList" :key="index"
								@click="tabClick(item)">{{item.name}}</span>
						</div>
					</a-col>
					<a-col :xs="0" :sm="4">
						<div class="tab-right">
							<a-button v-if="needConnect" class="btn" type="primary" size="large"
								@click="ConnectWallet">连接钱包</a-button>

							<a-dropdown v-else>
								<a-button class="btn" type="primary" size="large">{{utils.subString(owner, 5, 6)}}
									<DownOutlined />
								</a-button>
							</a-dropdown>

						</div>
					</a-col>
					<a-col :xs="24" :sm="0">
						<div class="tab-right">
							<MenuOutlined style="color: #fff;font-size: 25px;" @click="showDrawer" />
						</div>
					</a-col>
				</a-row>
			</div>
		</div>

		<a-drawer :open="OpenDrawer" placement="bottom" :closable="false" :maskClosable="true" @close="closeDrawer"
			height="400">
			<div class="wallet-info">
				<div class="avatar-view">
					<img class="avatar" src="../assets/icon.png" width="60px" height="55px" />
				</div>
				<div class="address-view">
					<span class="address" style="font-weight: 500;">Your Wallet</span>
					<span class="address">{{owner}}</span>
				</div>
			</div>

			<div class="tab-list-m">
				<div class="btn-view" v-if="needConnect">
					<a-button class="btn" type="primary" size="large" @click="ConnectWallet">连接钱包</a-button>
				</div>
				<van-cell-group style="background: transparent;" :border="false">
					<van-cell class="cell" title-class="cell-title-class" v-for="(item,index) in ServiceList"
						:key="index" :title="item.name" @click="tabClick(item)" :border="false" is-link />
				</van-cell-group>
			</div>
		</a-drawer>
	</div>
</template>

<script>
	import utils from '../assets/js_sdk/utils.js'
	import {
		saveAs
	} from 'file-saver';

	export default {
		name: 'HeaderView',
		props: {},
		data() {
			return {
				utils: utils,
				OpenDrawer: false,
				ServiceList: [{
						name: "创建/任务",
						view: "MintView"
					},
					{
						name: "我的/任务",
						view: "MintView"
					},
					{
						name: "代币/列表",
						view: "CatsView"
					}, {
						name: "持有/资产",
						view: "HoldsView"
					},
				],
				needConnect: true
			}
		},
		computed: {
			owner() {
				const address = this.$store.getters.getWallet
				if (address.length == 20) {
					return address
				} else {
					return utils.subString(address, 14, 12)
				}
			},
			wallet() {
				return this.$store.getters.getWallet;
			},
			mintWallet() {
				return this.$store.getters.getMintWallet;
			}
		},
		watch: {
			wallet(newVal) {
				if (newVal.length > 20) {
					this.needConnect = false
				} else {
					this.needConnect = true
				}
			},
		},
		methods: {
			tabClick(item) {
				if(this.needConnect){
					this.ConnectWallet()
					return
				}
				this.closeDrawer()
				this.$store.commit('updateView', item.view)
			},
			async ConnectWallet() {
				const address = await this.$wallet.Connect()
				if (address) {
					this.$store.commit('updateWallet', address)
					this.needConnect = false
					this.closeDrawer()
				} else {
					console.log('connect error')
				}
			},
			handleMenuClick(e) {
				switch (e.key) {
					case '1':
						break;
					case '2':
						location.reload()
						break;
				}

			},
			showDrawer() {
				this.OpenDrawer = true
			},
			closeDrawer() {
				this.OpenDrawer = false
			}
		}
	}
</script>

<style lang="scss">
	$text-main-color: #333333;


	.header {
		height: 3.8rem;
		background-color: rgba(64, 163, 194, 1);
	}

	.menu {
		margin: 0 auto;
		max-width: 80rem;
		height: 100%;
		display: flex;
		padding-left: 0.3125rem;
		padding-right: 0.3125rem;
	}

	.logo {
		flex: 1;
		height: 100%;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
	}

	.other {
		flex: 6;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}


	.icon {
		margin-left: 0.3125rem;
	}

	.domain {
		margin-left: 0.3125rem;
		color: #fff;
		font-size: 1.5rem;
		font-weight: bold;
	}

	.tab {
		width: 100%;
		height: 100%;
	}


	.tab-list {
		height: 100%;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
	}

	.tab-item {
		width: 10rem;
		color: #fff;
		font-size: 1.125rem;
		font-weight: 500;
		cursor: pointer;
		text-align: center;
	}

	.tab-item:hover {
		color: $text-main-color;
	}


	.tab-right {
		height: 100%;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-end;
		padding-right: 0.5rem;

		.ant-btn-primary {
			color: #fff;
			font-weight: 600;
			background-color: rgba(79, 208, 243, 1.0) !important;
			box-shadow: 0 2px 0 rgba(156, 187, 143, 0.1);
		}

		.ant-btn-primary:hover {
			background-color: rgba(64, 163, 194, 0.8) !important;
		}


		.btn {
			width: 166px;
			height: 43px;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;
			border-radius: 8px;
		}
	}



	.ant-drawer-content-wrapper {
		background: #f1f8f1 !important;
		// height: 400px !important;
		border-top-right-radius: 20px;
		border-top-left-radius: 20px;
		padding-bottom: 5px;
	}

	.ant-drawer-content {
		background-color: transparent !important;
	}

	.ant-drawer-body {
		padding: 0 !important;
	}

	.wallet-info {
		width: 100%;
		height: 6rem;
		position: absolute;
		top: 0px;
		padding-left: 0.9375rem;
		padding-right: 0.75rem;
		border-top-right-radius: 1.25rem;
		border-top-left-radius: 1.25rem;
		background-color: #f1f8f1;
		border-bottom: 1px solid #dbe0db;

		z-index: 99999;

		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
	}

	.tab-list-m {
		margin-top: 6rem;

		.btn-view {
			width: 100%;
			padding-left: 1.25rem;
			padding-right: 1.25rem;
			padding-top: 0.625rem;
			padding-bottom: 0.625rem;

			.btn {
				width: 100%;
				height: 50px;
				font-size: 18px;
			}
		}


		.cell {
			background: transparent;
			border-bottom: 1px solid #dbe0db;
			padding-left: 1.25rem;
			padding-right: 1.25rem;
		}

		.cell:active {
			background-color: rgba(135, 161, 123, 0.2);
		}

		.cell-title-class {
			font-size: 18px;
		}
	}

	.avatar-view {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		.avatar {
			border-radius: 8px;
			overflow: hidden;
		}
	}



	.address-view {
		flex: 1;
		height: 3.4375rem;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: center;
		padding-left: 0.625rem;
	}

	.address {
		flex: 2;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		font-size: 17px;
	}

	.ant-drawer-content-wrapper {
		height: 380px;
	}

	.menu-item {
		display: flex;
		flex-direction: row;
		align-items: center;
	}

	.icon {
		margin-right: 5px;
		font-size: 25px;
		color: $text-main-color;
	}

	.menu-item-text {
		font-size: 16px;
	}
</style>